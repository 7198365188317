
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';

	import ThumbnailOnlyLoader from '@pixcap/ui-core/components/content-loaders/ThumbnailOnlyLoader.vue';
	import { LIBRARY_TAB, PixcapLibraryPack as PixcapLibraryPackType, SEARCH_ROUTES } from '@/models/store/pixcapassets.interface';
	import PixcapLibraryPack from '@/components/pixcap-library/shared/PixcapLibraryPack.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import IconCaretRight from '@pixcap/ui-library/components/Icons/IconCaretRight.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';

	@Component({
		name: 'PackRecommender',
		components: {
			Button,
			IconCaretRight,
			BodyText,
			HeadingText,
			PixcapLibraryPack,
			ThumbnailOnlyLoader,
		},
	})
	export default class PackRecommender extends Vue {
		@Prop() readonly title: string;
		@Prop() readonly packName: string;
		@Prop() readonly isFetching: boolean;
		@Prop({ default: false }) readonly isBrandkit: boolean;
		@Prop({ default: false }) readonly isCharacterPack: boolean;
		@Prop() readonly items: PixcapLibraryPackType[];
		@Prop() readonly itemDisplayCount: number;

		handleShowAll() {
			this.$router.push({
				name: 'LibraryExploreSearchList',
				params: {
					tab: this.isBrandkit ? SEARCH_ROUTES.PACK_MOCKUPS : this.isCharacterPack ? SEARCH_ROUTES.PACK_CHARACTERS : SEARCH_ROUTES.PACK_ICONS,
					searchQuery: this.packName,
				},
			});
		}
	}
