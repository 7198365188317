
	import { Prop, Component, Vue } from 'vue-property-decorator';
	import PixcapDivThumbnail from '@pixcap/ui-library/components/PixcapDivThumbnail.vue';

	@Component({
		name: 'LibraryTemplateDetailsThumbnails',
		components: { PixcapDivThumbnail },
	})
	export default class LibraryTemplateDetailsThumbnails extends Vue {
		@Prop({ default: null }) thumbnailUrl: string;
		@Prop({ default: null }) animationUrl: string;
		@Prop({ default: '' }) readonly alt: string;
		@Prop() readonly label: string;
	}
