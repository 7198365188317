
	import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
	import HRendererScroll from '@pixcap/ui-library/components/HRendererScroll.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import Icon from '@pixcap/ui-library/common/Icon.vue';
	import FilterInput from '@/components/pixcap-library/FilterInput.vue';

	@Component({
		name: 'LibraryTabs',
		components: {
			Icon,
			FilterInput,
			HRendererScroll,
			HeadingText,
			BodyText,
		},
	})
	export default class LibraryTabs extends Vue {
		@Prop() tabs: { name: string; value: string; icon?: string }[];
		@Prop() activeTabValue: string;
		@Prop() disableWheel: boolean;
		@Prop({ default: 'default' }) size: 'small' | 'default';
		@Prop({ default: false }) disableMini: boolean;

		$refs: {
			refActiveLine: HTMLElement;
			refTabsList: HTMLElement;
		};

		onFilterChange(value: string) {
			this.$emit('handleSelectTab', value);
		}

		@Watch('activeTab')
		@Watch('tabs')
		onTabsChange() {
			this._handlePoseLine();
		}

		get filterOptionPlaceholder() {
			return this.tabs.find((tab) => tab.value === this.activeTabValue)?.name;
		}

		_handlePoseLine() {
			this.$nextTick(() => {
				if (!this.$refs.refTabsList) return;
				const $activeElem = this.$refs.refTabsList.querySelector('.tab-active') as HTMLElement;
				const $lineElem = this.$refs.refActiveLine;
				if ($activeElem && $lineElem && $lineElem.style) {
					$lineElem.style.left = `${$activeElem.offsetLeft}px`;
					$lineElem.style.width = `${$activeElem.clientWidth}px`;
				}
			});
		}

		mounted() {
			this._handlePoseLine();
		}
	}
